import { useRecoilValue, useSetRecoilState } from 'recoil';
import { subscriptionModalState } from '../../state/user/user.atom';
import { dmLicenseValidState } from '../../state/license/license.selector';

export default function useSubs() {
    const isLicValid = useRecoilValue(dmLicenseValidState)
    const setSubsModal = useSetRecoilState(subscriptionModalState)

    const withSubs = (fn: (...args: any[]) => void) => {
        return async (...args: any[]) => {
            if (isLicValid)
                return await fn(...args);
            return setSubsModal(true);
        };
    }

    return { withSubs, isLicValid }
}
