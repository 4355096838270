import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Button, Link } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { profileState } from '../../state/user/user.atom';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { isLoggedInState } from '../../state/user/user.selector';
import useAuth from '../auth/useAuth';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

function Nav() {
    const Icon = RocketLaunchIcon
    const { profileImg, profile } = useRecoilValue(profileState)
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const isLoggedIn = useRecoilValue(isLoggedInState)

    const pages = useMemo(() => {
        return isLoggedIn ? ['Home', 'MyBiz', 'Profile'] : ['Home', 'Pricing']
    }, [isLoggedIn])

    const settings = useMemo(() => {
        return isLoggedIn ? ['Account'] : []
    }, [isLoggedIn])

    // const { logout } = useFirebase()
    const { login, logout } = useAuth()

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Link onClick={scrollToTop} to={'/'} component={RouterLink} color={'#fff'} underline='none'>
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            BOOSTIFY
                        </Typography>
                    </Link>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Link component={RouterLink} to={`/${page.toLowerCase()}`} underline='none'>{page}</Link>
                                </MenuItem>
                            ))}
                        </Menu>

                        <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Link onClick={scrollToTop} to={'/'} component={RouterLink} color={'#fff'} underline='none'>
                            <Typography
                                variant="h5"
                                noWrap
                                sx={{
                                    mr: 2,
                                    display: { xs: 'flex', md: 'none' },
                                    flexGrow: 1,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                            >
                                BOOSTIFY
                            </Typography>
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', gap: 25, marginLeft: "10px" } }}>
                        {pages.map((page) => (
                            <Typography key={page} sx={{ my: 2, color: 'white', display: 'block' }} >
                                <Link onClick={scrollToTop} to={'/' + page.toLowerCase()} component={RouterLink} color={"#fff"} underline='none'>
                                    {page}
                                </Link>
                            </Typography>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt={profile?.name || 'vybz.in'} src={profileImg?.photourl || profileImg?.logourl || ''} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Link onClick={scrollToTop} to={'/' + setting.toLocaleLowerCase()} component={RouterLink} underline='none'>
                                        {setting}
                                    </Link>
                                </MenuItem>
                            ))}
                            <MenuItem onClick={handleCloseUserMenu}>
                                <Button color={isLoggedIn ? 'warning' : 'success'} onClick={isLoggedIn ? logout : login}>{isLoggedIn ? 'Logout' : "Login"}</Button>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Nav;
