import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../utils/firebase/firebase";
import { GreetPosterType } from "../../state/boostify/boostify.types";
import { Backdrop, CircularProgress, Container, Typography } from "@mui/material";
import BizCard from "../../Biz/BizCard";
import Carousel from "react-multi-carousel";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        partialVisibilityGutter: 40
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        partialVisibilityGutter: 40
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        partialVisibilityGutter: 40
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        partialVisibilityGutter: 20
    }
};


function TodayEvents({ istoday }: { istoday?: boolean }) {
    const [events, setEvents] = useState<GreetPosterType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getData = async () => {
            try {
                const today = new Date(new Date(new Date().setDate(new Date().getDate() - (istoday ? 0 : -1))).toDateString())
                const startts36 = Number(today).toString(36)
                const endts36 = Number(new Date(today).setDate(new Date(today).getDate() - (istoday ? 0 : -2))).toString(36)
                const q = query(collection(db, "boostify", "posters", "greet"), where('ts', '>=', startts36), where('ts', '<=', endts36))
                const resRef = await getDocs(q);

                if (resRef.empty) return setLoading(false)
                const res = resRef.docs.map(doc => ({ ...doc.data(), id: doc.id } as GreetPosterType)).filter(event => !!Object.keys(event.images).length)
                setLoading(false)
                setEvents([...res])
            } catch (error) {
                setLoading(false)
            }
        }
        getData();
    }, [])

    if (loading) return <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
    >
        <CircularProgress color="inherit" />
    </Backdrop>

    if (events.length)
        return (
            <Container>
                <Typography variant="h5">{istoday ? "Today's" : 'Upcoming'} Posters</Typography>
                <Carousel
                    additionalTransfrom={0}
                    arrows
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    // infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={true}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={2}
                    swipeable
                // partialVisible={true}
                >
                    {events.map((event) =>
                        <BizCard key={event.id as string} posterid={event.id as string} event={event} />
                    )}
                </Carousel>
            </Container>
        )

    return <></>


}

export default function Home() {

    return (
        <div>
            <TodayEvents istoday />
            <TodayEvents />
        </div>
    )
}
