import { useEffect, useState } from 'react'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { authState, loginModalState } from '../../state/user/user.atom'
import { User, onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../utils/firebase/firebase'
import { isLoggedInState } from '../../state/user/user.selector'
import GetData from './GetData'
import LoginModal from './Login'

export default function AuthComp() {
    const setAuthData = useSetRecoilState(authState)
    const resetAuthData = useResetRecoilState(authState)
    const setLoginModal = useSetRecoilState(loginModalState)
    const isLoggedIn = useRecoilValue(isLoggedInState)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (loading)
                setLoading(false)

            if (user) {
                const filteredUser = Object.fromEntries(
                    Object.entries(user)
                        .filter(([key, value]) => typeof value === 'string')
                ) as User;
                window.localStorage.removeItem('islogin')
                return setAuthData({ ...filteredUser, token: await user.getIdToken() })
            }
            const islogin = window.localStorage.getItem('islogin')
            if (islogin) setLoginModal(true)
            return resetAuthData()
        })

        return (() => {
            resetAuthData()
            if (unsubscribe)
                unsubscribe()
        })
    }, [])

    if (isLoggedIn) return <GetData />

    return <LoginModal />
}
