import { useEffect, useRef, useState } from "react";
import { GreetPosterType } from "../state/boostify/boostify.types";
import { Card, CardActionArea, CardContent, CardMedia, Chip, Skeleton, Typography } from "@mui/material";
import BizWarermark from "./BizWarermark";
import html2canvas from "html2canvas";
import bizlogo from './images/boostify-logo.png'
import BizPosters from "./BizPosters";
import useAuth from "../components/auth/useAuth";
import useSubs from "../components/subscription/useSubs";
import { getFormatedDate } from "../utils/timestamp/ts";
import { useNavigate } from "react-router-dom";

function ShowImageInCard({
    rawImg, bizImg, setBizImg, bizLoading, setBizLoading
}: {
    rawImg: string | ArrayBuffer | null, bizImg: string | ArrayBuffer | null, setBizImg: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>,
    bizLoading: boolean, setBizLoading: React.Dispatch<React.SetStateAction<boolean>>
}) {
    // const [loading, setLoading] = useState(true)
    const bizRef = useRef<HTMLDivElement>(null)

    const onLoadRawImg = () => {
        html2canvas(bizRef.current as HTMLDivElement, { width: 1080, height: 1080 })
            .then(canvas => {

                setBizImg(canvas.toDataURL())
                setBizLoading(false)
                // setRawImg('')
            })
            .catch(err => {
                console.error('Error on Load Raw IMG', err);
            })
    }

    if (bizLoading) return <>
        <Skeleton variant="rectangular" width="100%" height={'200px'} />
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <img
                            src={rawImg as string}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <img
                            src={bizlogo}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 100 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    <div
                        className="biz-img-header"
                        style={{ position: 'absolute', top: '25px', width: '100%' }}
                    >
                        <div className="business-name" style={{ textAlign: 'left', maxWidth: '80%', margin: '0 auto', fontSize: 40 + 'px', color: 'black', fontWeight: 'bolder' }}>
                            {'BOOSTIFY.BIZ'}
                        </div>
                    </div>
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '25px' }}
                    >
                        <div className="copyright" style={{ fontSize: 25 + 'px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>
                    <BizWarermark />
                </div>
            </div>
        </div>
    </>

    return <CardMedia
        component="img"
        image={bizImg as string}
        loading="lazy"
        alt={"Boostify-Biz"}
    />
}

export default function BizCard({ event, posterid }: { event: GreetPosterType, posterid: string }) {
    const [rawImg, setRawImg] = useState<string | ArrayBuffer | null>(null)

    const [loading, setLoading] = useState(true)
    const [bizLoading, setBizLoading] = useState(true)
    const [bizImg, setBizImg] = useState<string | ArrayBuffer | null>(null)
    const [open, setOpen] = useState(false)
    const [intersecting, setIntersecting] = useState(false)

    const imgid = Object.keys(event.images)[0]
    const imgData = event.images[imgid]

    const { withAuth } = useAuth()
    const { withSubs, isLicValid } = useSubs()

    const bodyRef = useRef<HTMLDivElement>(null)

    const navigation = useNavigate()

    useEffect(() => {
        const getImag = async () => {

            try {
                const res = await fetch(imgData.imgurl)
                const reader = new FileReader()
                reader.onloadend = () => {
                    setRawImg(reader.result)
                    setLoading(false)
                }
                reader.readAsDataURL(await res.blob())
            } catch (error) {
                setRawImg(imgData.imgurl)
                setLoading(false)
            }

        }
        if (intersecting)
            getImag()

        return () => {
            setRawImg('')
        }

    }, [intersecting])

    useEffect(() => {

        const handleInterSection = (entries: IntersectionObserverEntry[]) => {

            const [entry] = entries
            if (entry.isIntersecting) {
                setIntersecting(true)
            }
            return;
        }
        const intersectingObserver = new IntersectionObserver(handleInterSection)

        intersectingObserver.observe(bodyRef.current as Element)

        return () => {
            intersectingObserver.disconnect()
        }
    }, [])

    const openPosters = withAuth(withSubs(() => {
        navigation(`/greet/${posterid}`)
        return;
        setOpen(true)
    }))

    return (
        <div style={{ margin: '10px' }}>
            <Card>
                <CardActionArea
                    onClick={openPosters}
                >
                    {
                        loading ?
                            <Skeleton variant="rectangular" width="100%" height={'200px'} />
                            :
                            <>
                                <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                        <Chip
                                            label={getFormatedDate('dd-mm-yyyy', new Date(parseInt(event.ts as string, 36)))}
                                            color="default"
                                            size="small"
                                        />
                                    </div>
                                </div>
                                {
                                    event.images[imgid]?.tempimgurl ?
                                        <CardMedia
                                            component="img"
                                            image={event.images[imgid].tempimgurl as string}
                                            alt={event.title}
                                            loading="lazy"
                                        />
                                        :
                                        <ShowImageInCard
                                            bizImg={bizImg} setBizImg={setBizImg}
                                            bizLoading={bizLoading} setBizLoading={setBizLoading}
                                            rawImg={rawImg}
                                        />
                                }
                            </>
                    }
                    <div ref={bodyRef} ></div>
                </CardActionArea>
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {event.title}
                    </Typography>
                </CardContent>
                {
                    isLicValid && open &&
                    <BizPosters open={open} onClose={() => setOpen(false)} event={event} />
                }
            </Card>
        </div>
    )
}
