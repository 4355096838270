
import { forwardRef, useEffect, useRef, useState } from 'react';
import { GreetPosterType, PosterImagesType } from '../state/boostify/boostify.types'
import { AppBar, Button, Card, CardActionArea, CardMedia, Container, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Skeleton, Slide, Toolbar, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import BizPoster from './BizPoster';
import html2canvas from 'html2canvas';
import { useRecoilValue } from 'recoil';
import useAuth from '../components/auth/useAuth';
import useSubs from '../components/subscription/useSubs';
import { todayDownloadIDState } from '../state/license/license.atom';
import boostifyLogo from './images/boostify-logo.png'
import BizImageTemplate6 from './BizImageTemplates/BizImageTemplate6';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EachImage({
    image, imageid, event
}: {
    image: PosterImagesType, imageid: string, event: GreetPosterType
}) {
    const [open, setOpen] = useState(false)
    const [openUpgrade, setOpenUpgrade] = useState(false)
    const [rawImg, setRawImg] = useState<string | ArrayBuffer | null>(null)
    const [bizImg, setBizImg] = useState<string | ArrayBuffer | null>('' as string | ArrayBuffer | null)
    const [loading, setLoading] = useState(!image?.tempimgurl)
    const [bizLoading, setBizLoading] = useState(!image?.tempimgurl)

    const downloadID = useRecoilValue(todayDownloadIDState)

    const bizRef = useRef<HTMLDivElement>(null)

    const { withAuth } = useAuth()
    const { withSubs, isLicValid } = useSubs()

    const onLoadRawImg = () => {
        html2canvas(bizRef.current as HTMLDivElement, { width: 1080, height: 1080 })
            .then(canvas => {
                setBizImg(canvas.toDataURL())
                setBizLoading(false)
                // setRawImg('')
            })
            .catch(err => {
                console.error('Error on Load Raw IMG', err);
            })
    }

    useEffect(() => {
        const getImag = async () => {
            try {
                const res = await fetch(image.imgurl)
                const reader = new FileReader()
                reader.onloadend = () => {
                    setRawImg(reader.result)
                    setLoading(false)
                }
                reader.readAsDataURL(await res.blob())
            } catch (error) {
                setRawImg(image.imgurl)
                setLoading(false)
            }

        }
        if ((image?.tempimgurl ? open : true) && !rawImg)
            getImag()

    }, [open])

    useEffect(() => {
        return () => {
            setRawImg('')
        }
    }, [])

    const openPoster = withAuth(withSubs(() => {
        if (downloadID && (downloadID !== imageid)) return setOpenUpgrade(true)
        if (loading || bizLoading) return
        setOpen(true)
    }))

    const handleCloseUpgradDialog = () => {
        setOpenUpgrade(false);
    };

    return (
        <>
            {
                !loading && bizLoading && !image?.tempimgurl &&
                <BizImageTemplate6
                    onLoadRawImg={onLoadRawImg}
                    bizRef={bizRef}
                    rawImg={rawImg as string}
                    templateData={{
                        bnmae: 'BOOSTIFY.BIZ', pname: '', watermark: 'BOOSTIFY.BIZ', logo: boostifyLogo, profile: boostifyLogo,
                        mail: 'care@bosstify.biz',
                        mobile: '9994663906',
                        mobile2: '9994149559',
                        web: 'www.boostify.biz',
                    }}
                />
            }
            <Card>
                <CardActionArea onClick={openPoster}>
                    {
                        ((loading || bizLoading) && !image?.tempimgurl) ?
                            <Skeleton variant="rectangular" width="100%" height={"200px"} />
                            :
                            <CardMedia
                                component="img"
                                // height="140"
                                image={(image.tempimgurl || bizImg || rawImg || image.imgurl) as string}
                                alt={image.title}
                            />
                    }
                </CardActionArea>
            </Card>
            {
                open && isLicValid && rawImg && !loading && (downloadID ? downloadID === imageid : true) &&
                <BizPoster open={open} setOpen={setOpen} event={event} imageid={imageid} rawImg={(rawImg) as string} />
            }
            <Dialog
                open={openUpgrade}
                TransitionComponent={Transition}
                // keepMounted
                onClose={handleCloseUpgradDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Upgrade To Infinity Plan</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        You need to upgrade to Infinity Plan to download multiple posters
                    </DialogContentText>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleCloseUpgradDialog}>Upgrade</Button>
                </DialogActions> */}
            </Dialog>
        </>
    );
}

export default function BizPosters({
    event, open, onClose
}: {
    event: GreetPosterType, open: boolean, onClose: () => void
}) {

    return (
        <Dialog
            open={open} onClose={onClose}
            fullScreen
            scroll='paper'
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {event.title}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={onClose}>
                        Close
                    </Button>
                </Toolbar>
            </AppBar>
            <Container sx={{ p: 2 }}>
                {Object.keys(event.images).length > 0 ? (
                    <>
                        <Grid container spacing={2}>
                            {Object.keys(event.images).map((imageid) => {
                                const image = event.images[imageid];
                                return <Grid item xs={6} sm={4} md={3} lg={3} key={imageid}><EachImage image={image} imageid={imageid} event={event} /> </Grid>
                            })}
                        </Grid>
                    </>
                ) : (
                    <Typography variant="h6" component="h2">No Images Found</Typography>
                )}
            </Container>
        </Dialog>
    )
}

export { EachImage }