import { LegacyRef } from 'react'
import { templateDataType } from '../../state/boostify/boostify.types'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import fbIcon from '../assets/fb.png'
import instaIcon from '../assets/insta.png'
import xIcon from '../assets/x.png'
import ytIcon from '../assets/yt.png'
import BizWarermark from '../BizWarermark';
import './BizImageTemplate6.css'

export default function BizImageTemplate6({
    templateData, bizRef, rawImg, onLoadRawImg
}: {
    templateData: templateDataType,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void
}) {

    return (
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    // overflow: 'scroll'
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{
                            position: 'absolute', top: '0', left: '0',
                            // transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <img
                            src={rawImg}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    {
                        templateData.logo ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '8%', left: '8%', transform: 'translate(-10%, -50%)' }}
                            >
                                <img
                                    src={templateData.logo}
                                    alt={templateData.bnmae ?? 'Boostify-Biz'}
                                    // onLoad={onLoadRawImg}
                                    style={{
                                        width: '120px',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                            : ''
                    }
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: 2 + '%', width: '80%', right: '8%' }}
                    >
                        <div className="business-name" style={{ textAlign: 'right', margin: '0 auto', fontSize: '45px', color: 'black', fontWeight: 'bolder', }}>
                            {templateData.bnmae ?? 'BOOSTIFY.BIZ'}
                        </div>
                    </div>

                    <div
                        className="biz-contact-info"
                        style={{ position: 'absolute', top: '87%', width: '100%', }}
                    >
                        <div className="biz-mail-and-web"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: 'white',
                                width: '100%',
                                marginBottom: '5px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex', gap: 5, fontSize: '20px', width: '50%', justifyContent: 'center',
                                    background: '#0056a2',
                                    alignItems: 'center',
                                    marginRight: '-60px',
                                    position: 'relative',
                                }}
                                className='biz-mail-temp6'
                            >
                                <div style={{ display: 'flex' }}><EmailIcon /></div>
                                <div>{templateData.mail}</div>
                            </div>
                            <div
                                className='biz-web-temp6'
                                style={{
                                    display: 'flex', gap: 5, fontSize: '20px', width: '50%', justifyContent: 'center',
                                    background: '#ec1d8b',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <div style={{ display: 'flex' }}><LanguageIcon /></div>
                                <div>{templateData.web}</div>
                            </div>
                        </div>

                        <div className="biz-mobile-nums"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 5,
                                color: 'white',
                                height: '33px',
                            }}
                        >
                            <div
                                className='biz-mobile1-temp6'
                                style={{
                                    display: 'flex', gap: 5, fontSize: '30px',
                                    width: '80%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#ec1d25',
                                    position: 'relative',
                                }}
                            >
                                <div style={{ display: 'flex' }}><CallIcon /></div>
                                <div>{templateData.mobile}{templateData.mobile2 ? ',' + templateData.mobile2 : ''}</div>
                            </div>
                        </div>

                        <div className="biz-address">
                            <div style={{ fontSize: '30px', textAlign: 'center' }}>
                                {templateData.address}
                            </div>
                        </div>

                    </div>
                    {
                        templateData?.watermark && <BizWarermark watermark={templateData.watermark} />
                    }
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
                    >
                        <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
