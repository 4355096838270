import { atom } from "recoil";
import { EmpRoleType, ThemeType } from "./user.types";
import { User } from "firebase/auth";
import { ProfileDataType, UserDataType } from "./user.types";

export const themeState = atom({
    key: 'themeState',
    default: 'light' as ThemeType
});

export const userState = atom({
    key: 'userState',
    default: {} as UserDataType,
})

export const authState = atom({
    key: 'authState',
    default: { uid: '', token: '' } as User & { token: string },
});

export const profileState = atom({
    key: 'profileState',
    default: {} as ProfileDataType
})

export const loginModalState = atom({
    key: 'loginModalState',
    default: false
})

export const subscriptionModalState = atom({
    key: 'subscriptionModalState',
    default: false
})

export const empRoleState = atom({
    key: 'EmpRoleState',
    default: {} as EmpRoleType
})

export const masterRoleState = atom({
    key: 'MasterRoleState',
    default: {} as EmpRoleType
})