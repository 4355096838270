import { LegacyRef } from 'react'
import { templateDataType } from '../../state/boostify/boostify.types'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import fbIcon from '../assets/fb.png'
import instaIcon from '../assets/insta.png'
import xIcon from '../assets/x.png'
import ytIcon from '../assets/yt.png'
import BizWarermark from '../BizWarermark';

export default function BizImageTemplate5({
  templateData, bizRef, rawImg, onLoadRawImg
}: {
  templateData: templateDataType,
  bizRef: LegacyRef<HTMLDivElement>,
  rawImg: string,
  onLoadRawImg: () => void
}) {

  return (
    <div
      style={{ position: 'relative' }}
    >
      <div
        className="biz-img-container-to-hide"
        style={{
          // overflow: 'scroll'
          position: 'absolute',
          top: -99999999
        }}
      >
        <div
          className="biz-img-container"
          style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
          ref={bizRef}
        >
          <div
            className="biz-img-body"
            style={{
              position: 'absolute', top: '0', left: '0',
              // transform: 'translate(-50%, -50%)'
            }}
          >
            <img
              src={rawImg}
              alt={'Boostify-Biz'}
              onLoad={onLoadRawImg}
              style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
            />
          </div>
          {
            templateData.logo ?
              <div
                className="biz-img-body"
                style={{ position: 'absolute', top: '8%', left: '8%', transform: 'translate(-10%, -50%)' }}
              >
                <img
                  src={templateData.logo}
                  alt={templateData.bnmae ?? 'Boostify-Biz'}
                  // onLoad={onLoadRawImg}
                  style={{
                    width: '120px',
                    objectFit: 'cover'
                  }}
                />
              </div>
              : ''
          }
          <div
            className="biz-img-body"
            style={{ position: 'absolute', top: 2 + '%', width: '80%', right: '8%' }}
          >
            <div className="business-name" style={{ textAlign: 'right', margin: '0 auto', fontSize: '45px', color: 'black', fontWeight: 'bolder', }}>
              {templateData.bnmae ?? 'BOOSTIFY.BIZ'}
            </div>
            {
              templateData.web &&
              <div className="biz-web">
                <div style={{ fontSize: '30px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 5 }}>
                  <div style={{ display: 'flex' }}>
                    <LanguageIcon />
                  </div>
                  <div>
                    {templateData?.web}
                  </div>
                </div>
              </div>
              // <div style={{ textAlign: 'right', justifyContent: 'end', margin: '0 auto', fontSize: '30px', color: 'black', alignItems: 'center', display: 'flex' }}>
              //   <div style={{ display: 'flex' }}><LanguageIcon /></div>
              //   <div>{templateData?.web}</div>
              // </div>
            }
          </div>

          <div
            className="biz-contact-info"
            style={{ position: 'absolute', top: '87%', width: '100%', }}
          >

            <div className="biz-primary-contact" style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-evenly', gap: 5, color: 'white' }}>
              <div style={{ border: '5px dashed #ec1d25' }}>
                <div style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ec1d25', padding: '5px 50px' }}>
                  <div style={{ display: 'flex' }}>
                    <CallIcon />
                  </div>
                  <div>
                    {templateData?.mobile}{templateData.mobile2 ? ',' + templateData.mobile2 : ''}
                  </div>
                </div>
              </div>
              <div style={{ border: '5px dashed #0056a2' }}>
                <div style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#0056a2', padding: '5px 50px' }}>
                  <div style={{ display: 'flex' }}>
                    <EmailIcon />
                  </div>
                  <div>
                    {templateData?.mail}{templateData.mail2 ? ',' + templateData.mail2 : ''}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="biz-sm-data" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '5px', gap: 5 }}>
              {
                templateData?.sm?.fb ?
                  <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={fbIcon} width={'30px'} height={"30px"} />{''}</span>
                  : ''
              }
              {
                templateData?.sm?.insta ?
                  <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={instaIcon} width={'30px'} height={"30px"} />{''}</span>
                  : ''
              }
              {
                templateData?.sm?.tw ?
                  <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={xIcon} width={'30px'} height={"30px"} />{''}</span>
                  : ''
              }
              {
                templateData?.sm?.yt ?
                  <span style={{ fontSize: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={ytIcon} width={'30px'} height={"30px"} />{''}</span>
                  : ''
              }
            </div> */}

            <div className="biz-address">
              <div style={{ fontSize: '30px', textAlign: 'center' }}>
                {templateData.address}
              </div>
            </div>

          </div>
          {
            templateData?.watermark && <BizWarermark watermark={templateData.watermark} />
          }
          <div
            className="biz-img-footer"
            style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
          >
            <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
              All Rights Reserved. © 2024 to www.Boostify.Biz
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
