import React, { useEffect, useState } from 'react'
import { Modal } from 'react-responsive-modal'
import { useHotkeys } from 'react-hotkeys-hook'
import { Button, Tooltip } from '@mui/material'
import 'react-responsive-modal/styles.css';

function ReactModal(
    {
        buttonSize = 'btn-small',
        buttonStyle = 'btn-success',
        buttontext = 'Click Me',
        buttonclass = '',
        ButtComp,
        isopen = false,
        isclose = false,
        onClose,
        tooltip = false,
        showCloseIcon = true,
        closeOnOverlayClick = true,
        closeOnEsc = true,
        closebutton = false,
        closebuttontext = 'close',
        closebuttonstyle,
        hotkeys = { hotkey: 'ctrl+shift+a+b+c+d', enableOnFormTags: ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault: true },
        modelclass = '',
        Component,
        children,
        isbutton = true,
        isicon = false,
        ...props
    }: {
        buttonSize?: string,
        buttonStyle?: string,
        buttontext?: string,
        buttonclass?: string,
        ButtComp?: React.JSXElementConstructor<any>,
        isopen?: boolean,
        isclose?: boolean,
        onClose?: () => void,
        tooltip?: any,
        showCloseIcon?: boolean,
        closeOnOverlayClick?: boolean,
        closeOnEsc?: boolean,
        closebutton?: boolean,
        closebuttontext?: string,
        closebuttonstyle?: string,
        hotkeys?: any,
        modelclass?: string,
        Component?: React.JSXElementConstructor<any>,
        children?: React.ReactNode,
        isbutton?: boolean,
        isicon?: boolean,
        props?: any

    }
) {
    const [isModalOpen, setIsModalOpen] = useState(isopen)
    const { hotkey = 'ctrl+shift+a+b+c+d', enableOnFormTags = ['INPUT', 'SELECT', 'TEXTAREA'], preventDefault = true } = hotkeys

    useHotkeys(
        hotkey,
        () => setIsModalOpen(true),
        { enableOnFormTags, preventDefault }
    )

    useEffect(() => {
        setIsModalOpen(!!isopen)
    }, [isopen])

    useEffect(() => {
        if (isclose)
            closeModal()
    }, [isclose])

    const closeModal = () => {
        setIsModalOpen(false)
        if (onClose) return onClose()
    }

    const butComp = () => isbutton ?
        <>
            {
                ButtComp ?
                    <ButtComp
                        onClick={() => setIsModalOpen(true)}
                    />
                    :
                    <Button
                        type='button'
                        // buttonSize={buttonSize}
                        // buttonStyle={buttonStyle}
                        onClick={() => setIsModalOpen(true)}
                    >
                        {buttontext || 'Click Me'}
                    </Button>
            }
        </>
        : isicon ?
            <span
                className={buttonclass}
                style={{
                    cursor: 'pointer',
                    margin: '5px'
                }}
                onClick={() => setIsModalOpen(true)}
            >
                {buttontext || 'Click Me'}
            </span>
            : ''

    return (
        <>
            {
                tooltip ? <Tooltip {...tooltip}>{butComp()}</Tooltip>
                    :
                    <>{butComp()}</>
            }
            {
                isModalOpen &&
                <Modal
                    open={isModalOpen}
                    onClose={closeModal}
                    center
                    closeOnOverlayClick={closeOnOverlayClick}
                    closeOnEsc={closeOnEsc}
                    showCloseIcon={showCloseIcon}
                    classNames={{
                        overlay: 'customOverlay',
                        modal: `customModal ${modelclass} hide-scroll-bar`,
                    }}
                >
                    {
                        children ? children : null
                    }
                    {
                        Component ?
                            <Component {...props} closeModal={closeModal} />
                            : ''
                    }
                    {
                        closebutton ?
                            <Button
                                // buttonSize={'btn-small'}
                                // buttonStyle={closebuttonstyle}
                                // buttontext={closebuttontext}
                                onClick={closeModal}
                            >
                                {closebuttontext}
                            </Button>
                            : ''
                    }
                </Modal>
            }
        </>
    )
}

export default ReactModal
