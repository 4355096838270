import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAuth } from 'firebase/auth'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    // clientId: process.env.REACT_APP_CLIENT_ID
};

const app = initializeApp(config)
const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth(app)

// if (((process.env.NODE_ENV === 'development') && (process.env.REACT_APP_DB_EMULATOR === 'YES'))) {
//     console.log('inside emulator');
//     fdb.useEmulator(process.env.REACT_APP_DB_EMULATOR_HOST, process.env.REACT_APP_DB_EMULATOR_PORT)
// }

// fdb.enablePersistence({ experimentalTabSynchronization: true, synchronizeTabs: true })
//     .catch((err) => {
//         console.error('pers error', err);
//     })

export { app, db, storage, auth }
