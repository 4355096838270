import { atom } from "recoil";
import { dmLicenseType } from "./license.types";

export const dmLicenseState = atom({
    key: 'dmLicenseState',
    default: {} as dmLicenseType,
});

export const todayDownloadIDState = atom({
    key: 'todayDownloadIDState',
    default: '',
})
