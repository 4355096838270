import { LegacyRef } from 'react'
import { templateDataType } from '../../state/boostify/boostify.types'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import fbIcon from '../assets/fb.png'
import instaIcon from '../assets/insta.png'
import xIcon from '../assets/x.png'
import ytIcon from '../assets/yt.png'
import BizWarermark from '../BizWarermark';
import './BizImageTemplate8.css'

export default function BizImageTemplate8({
    templateData, bizRef, rawImg, onLoadRawImg
}: {
    templateData: templateDataType,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void
}) {

    return (
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    // overflow: 'scroll'
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{
                            position: 'absolute', top: '0', left: '0',
                            // transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <img
                            src={rawImg}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
                        />
                    </div>

                    {
                        templateData.logo ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '8%', left: '8%', transform: 'translate(-10%, -50%)' }}
                            >
                                <img
                                    src={templateData.logo}
                                    alt={templateData.bnmae ?? 'Boostify-Biz'}
                                    // onLoad={onLoadRawImg}
                                    style={{
                                        width: '120px',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                            : ''
                    }

                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: 2 + '%', width: '80%', right: '8%' }}
                    >
                        <div
                            className="business-name"
                            style={{
                                textAlign: 'right', margin: '0 auto', fontSize: '45px', fontWeight: 'bolder',
                            }}
                        >
                            <div style={{ background: 'rgba(0,0,0,0.5)', color: 'white', display: 'inline-block', padding: '10px 10px', borderRadius: '25px' }}>
                                {templateData.bnmae ?? 'BOOSTIFY.BIZ'}
                            </div>
                        </div>
                    </div>


                    <div
                        className="biz-contact-info"
                        style={{
                            position: 'absolute', bottom: 0, width: '80%', left: '0', paddingLeft: '210px', height: '160px',
                            background: 'rgba(0,0,0,0.5)', color: 'white',
                            borderRadius: '0 20px 0 0',
                        }}
                    >
                        <div style={{ fontSize: '30px' }}>{templateData.pname}</div>
                        <div
                            className='biz-mobile'
                            style={{
                                display: 'flex', gap: 5, fontSize: '25px', justifyContent: 'flex-start',
                                // background: '#ec1d8b',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                        >
                            <div style={{ display: 'flex' }}><CallIcon /></div>
                            <div>{templateData.mobile}{templateData.mobile2 ? `, ${templateData.mobile2}` : ''}</div>
                        </div>

                        <div className="biz-mail-and-web" style={{ display: 'flex', gap: '20px' }}>
                            <div
                                style={{
                                    display: 'flex', gap: 5, fontSize: '20px', justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                                className='biz-mail'
                            >
                                <div style={{ display: 'flex' }}><EmailIcon /></div>
                                <div>{templateData.mail}</div>
                            </div>
                            <div
                                className='biz-web'
                                style={{
                                    display: 'flex', gap: 5, fontSize: '20px', justifyContent: 'center',
                                    // background: '#ec1d8b',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <div style={{ display: 'flex' }}><LanguageIcon /></div>
                                <div>{templateData.web}</div>
                            </div>
                        </div>

                        <div className="biz-address">
                            <div style={{ fontSize: '15px' }}>
                                {templateData.address}
                            </div>
                        </div>

                    </div>

                    {
                        templateData.profile ?
                            <div
                                className="biz-img-body"
                                style={{
                                    height: '200px', width: '200px',
                                    position: 'absolute', bottom: '0', left: '0',
                                }}
                            >
                                <img
                                    src={templateData.profile}
                                    alt={templateData.pname ?? 'Boostify-Biz'}
                                    // onLoad={onLoadRawImg}
                                    style={{
                                        width: '200px',
                                        objectFit: 'cover',
                                        border: '1px solid black', borderRadius: '0 20px 0 0',
                                    }}
                                />
                            </div>
                            : ''
                    }


                    {
                        templateData?.watermark && <BizWarermark watermark={templateData.watermark} />
                    }
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
                    >
                        <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
