import { loginModalState, subscriptionModalState } from '../../state/user/user.atom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material'
import { useMemo, useState } from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { isLoggedInState } from '../../state/user/user.selector';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from "@mui/icons-material/Call"

const tiers = [
    {
        title: 'Starter',
        price: '1',
        per: 'day',
        yearly: '365',
        withgst: 431,
        description: [
            'Greeting Poster',
            '1 Poster / Day',
            'Personalized Profile',
        ],
        buttonText: 'Choose',
        buttonVariant: 'outlined',
    },
    {
        title: 'Infinite',
        subheader: 'Recommended',
        price: '99',
        yearly: '1188',
        withgst: 1402,
        per: 'month',
        description: [
            'Everything in Starter Plan',
            'Infinite Posters',
            'UPI QR Code Generation',
            'Tools',
            'Different Category Posters (Coming Soon)'
        ],
        buttonText: 'Start now',
        buttonVariant: 'contained',
    },
    {
        title: 'Digital Marketing',
        // price: '1999',
        startsfrom: '4999',
        description: [
            'Personalized Posters',
            'Product Posters',
            'Paid Promotions',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

interface PaymentDialogProps {
    open: boolean;
    onClose: () => void;
}

const PaymentDialog = ({
    am, onClose
}: {
    am: number,
    onClose: () => void
}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const name = 'Noyyal Technologies'
    const upi = 'noyyaltechnologies@ybl'

    const { paymentOptions, upiURL } = useMemo(() => {
        const eName = encodeURIComponent(name || '')

        const baseupi = upi
        // const temp = `upi://pay?pa=${bankdetails.accno}@${bankdetails.ifsc}.ifsc.npci${`&pn=${encodeURIComponent(upiname || 'GK Angadi')}`}&cu=INR${amount ? `&am=${Number(amount).toFixed(2)}` : ''}`

        const base = `pay?pa=${baseupi}&pn=${eName}&cu=INR`
        let baseparams = `://pay?pa=${baseupi}&pn=${eName}&cu=INR`
        if (am) baseparams += `&am=${am}`

        const upiURL = `upi${baseparams}`
        const gpay = `gpay://upi/${base}`
        const phonepe = `phonepe${baseparams}`

        const paytm = `paytm${baseparams}`
        const bhim = `bhimupi${baseparams}`
        const airtel = `myairtel${baseparams}`
        const jiomoney = `jiomoney${baseparams}`
        // const cred = `cred${baseparams}`
        const cred = `cred://pay?name=${eName}&amount=${am}&upi=${upi}` //not working
        const amazonpay = `amazonpay${baseparams}` //amzn://apps/com.amazon.windowshop/paynow?channel=upi&partner=<partner_name>&transaction=<transaction_id>&amount=<amount>&merchant=<merchant_id>
        const mobikwik = `mobikwik://pre?amount=${am}&to=${upi}&action=upi`

        return {
            paymentOptions: [
                {
                    tooltip: 'Google Pay',
                    name: 'Google Pay',
                    image: 'https://firebasestorage.googleapis.com/v0/b/bizsnap-app.appspot.com/o/defaults%2FpaymentTypes%2FGoogle-Pay-logo.png?alt=media&token=9d4736f6-4937-4891-bf7d-692ffb5e00d3',
                    url: gpay
                },
                {
                    tooltip: 'UPI',
                    name: 'UPI',
                    image: 'https://firebasestorage.googleapis.com/v0/b/bizsnap-app.appspot.com/o/defaults%2FpaymentTypes%2F2560px-UPI-Logo-vector.svg.png?alt=media&token=c0705a1f-02b2-4aa3-8028-1cf23ea2fbbb',
                    url: upiURL
                },
                {
                    tooltip: 'PhonePe',
                    name: 'PhonePe',
                    image: 'https://firebasestorage.googleapis.com/v0/b/bizsnap-app.appspot.com/o/defaults%2FpaymentTypes%2FPhonePe-Logo.wine.png?alt=media&token=a9f20aa6-ee69-4a67-8023-8f1a4f5e365a',
                    url: phonepe
                },
            ], upiURL
        }
    }, [am])

    const openApp = (url: string) => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.href = url;
        } else {
            navigator.clipboard.writeText(url);
            alert('UPI URL copied to clipboard');
        }
    };

    return (
        <>
            <DialogTitle>Payment Instructions</DialogTitle>
            <DialogContent>
                <Typography gutterBottom>
                    Please make the payment using by scanning the qr code
                </Typography>
                <Box className="payment-UPI-QR">
                    <Avatar
                        // alt={name}
                        src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(upiURL)}`}
                        sx={{ width: 150, height: 150, margin: '0 auto' }}
                        variant='square'
                    />
                    <Typography variant="h6" style={{ textAlign: 'center', margin: '10px 0' }}>Scan QR to pay<br />or<br />Click on any UPI Payment App below</Typography>
                </Box>
                {/* <Typography variant="body2" gutterBottom>
                    Or you can pay using UPI apps like Google Pay, PhonePe, Paytm, etc. by following the steps below:
                </Typography> */}
                {/* <Box className="payment-detail">
                    <Grid container spacing={2} justifyContent="center" alignItems={'center'}>
                        {paymentOptions.map((option, index) => (
                            <Grid item xs={4} sm={4} md={4} key={index}>
                                <Tooltip title={option.tooltip}>
                                    <IconButton onClick={() => openApp(option.url)}>
                                        <Box
                                            component="img"
                                            src={option.image}
                                            alt={option.name}
                                            sx={{ width: '100%', height: 'auto' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box> */}
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" gutterBottom>
                    After making the payment, Send screenshot of payment to <a href={`https://wa.me/91${9994149559}?text=${encodeURIComponent(`Hi,\nI'm interested in your product.\nMy signup email is: [Your email]\nI have made a payment of Rs: ${am}`)}`} target="_blank">+91 9994149559</a> or click on the WhatsApp icon below
                </Typography>
                <Tooltip title="WhatsApp" arrow placement="top">
                    <IconButton size="small" onClick={() => window.open(`https://wa.me/91${9994149559}?text=${encodeURIComponent(`Hi,\nI'm interested in your product.\nMy signup email is: [Your email]\nI have made a payment of Rs: ${am}`)}`)}><WhatsAppIcon fontSize='medium' color='primary' /></IconButton>
                </Tooltip>
            </DialogContent>
            <DialogContent>
                <Typography variant='caption' color='textSecondary'>
                    By clicking on the payment options above, you agree to our
                    <Link href="/policies" color="primary" component={'a'} target='_blank'>
                        {" "}policies{" "}
                    </Link>
                    and
                    <Link href="/refund-policy" color="primary" component={'a'} target='_blank'>
                        {" "}refund policy
                    </Link>
                    .
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export function Pricing() {
    const [dialoge, setDialoge] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(1)
    const isLoggedIn = useRecoilValue(isLoggedInState)
    const setLoginModal = useSetRecoilState(loginModalState)

    return (
        <>
            <Container
                id="pricing"
                sx={{
                    pt: { xs: 4, sm: 12 },
                    pb: { xs: 8, sm: 16 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4" color="text.primary">
                        Pricing
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {/* Welcome to our digital marketing support platform! Empower your business with personalized posters tailored to your brand's unique identity. Our innovative tools allow users to seamlessly integrate their business and profile data into captivating designs, creating impactful marketing materials in minutes. Whether you're a small startup or a seasoned entrepreneur, our pricing plans are designed to fit your needs and budget. Choose from our range of subscription options, each offering access to our full suite of features, including customizable templates, premium graphics, and expert support. Elevate your marketing strategy today with our user-friendly platform and unlock the potential for greater brand visibility and customer engagement. Sign up now and start creating stunning posters that reflect the essence of your business! */}
                        Unlock the power of personalized marketing with our platform designed to support your digital journey. Effortlessly generate custom posters infused with your business and profile data, boosting your brand's visibility in moments. Our pricing plans cater to businesses of all sizes, ensuring affordability without compromising on quality. Join us today to revolutionize your marketing approach and elevate your brand's presence online.
                    </Typography>
                    {
                        !isLoggedIn && <>
                            <br />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setLoginModal(true)}
                            >
                                Login / Signup
                            </Button>
                            <br />
                            <Typography variant="caption" textAlign="center" sx={theme => ({ opacity: 0.8, color: theme.palette.mode === 'light' ? 'text.primary' : 'text.primary' })}>
                                By clicking &quot;Login / Signup&quot; you agree to our&nbsp;
                                <Link href="/policies" color="primary">
                                    Terms & Conditions & Other Policies
                                </Link>
                                .
                            </Typography>
                        </>
                    }

                </Box>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                    {tiers.map((tier, index) => (
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={tier.title === 'Enterprise' ? 12 : 6}
                            md={4}
                        >
                            <Card
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                    border: tier.title === 'Infinite' ? '1px solid' : undefined,
                                    borderColor:
                                        tier.title === 'Infinite' ? 'primary.main' : undefined,
                                    background:
                                        tier.title === 'Infinite'
                                            ? 'linear-gradient(#033363, #021F3B)'
                                            : undefined,
                                }}
                            >
                                <CardContent>
                                    <Box
                                        sx={{
                                            mb: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            color: tier.title === 'Infinite' ? 'grey.100' : '',
                                        }}
                                    >
                                        <Typography component="h3" variant="h6">
                                            {tier.title}
                                        </Typography>
                                        {tier.title === 'Infinite' && (
                                            <Chip
                                                icon={<AutoAwesomeIcon />}
                                                label={tier.subheader}
                                                size="small"
                                                sx={{
                                                    background: (theme) =>
                                                        theme.palette.mode === 'light' ? '' : 'none',
                                                    backgroundColor: 'primary.contrastText',
                                                    '& .MuiChip-label': {
                                                        color: 'primary.dark',
                                                    },
                                                    '& .MuiChip-icon': {
                                                        color: 'primary.dark',
                                                    },
                                                }}
                                            />
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'baseline',
                                            color: tier.title === 'Infinite' ? 'grey.50' : undefined,
                                        }}
                                    >
                                        {
                                            tier.price ?
                                                <>
                                                    <Typography component="h3" variant="h2">
                                                        ₹{tier.price}
                                                    </Typography>
                                                    <Typography component="h3" variant="h6">
                                                        &nbsp;/ {tier.per}
                                                    </Typography>
                                                </>
                                                :
                                                <>
                                                    <Typography component="h3" variant="h2">
                                                        Custom
                                                    </Typography>
                                                    <Typography component="h3" variant="h6">
                                                        &nbsp;
                                                    </Typography>
                                                </>
                                        }
                                    </Box>
                                    {
                                        tier.yearly &&
                                        <Typography variant='caption' color="text" sx={{ color: tier.subheader ? 'white' : 'inherit' }}>
                                            ₹{tier.yearly} / Year<br />
                                        </Typography>
                                    }
                                    {
                                        tier.startsfrom &&
                                        <Typography variant='caption' color="text" sx={{ color: tier.subheader ? 'white' : 'inherit' }}>
                                            Starts from ₹{tier.startsfrom} / Month<br />
                                        </Typography>
                                    }
                                    <Typography variant='caption' color="text" sx={{ color: tier.subheader ? 'white' : 'inherit' }}>
                                        18% GST Applicable | Charged Annually
                                    </Typography>
                                    <Divider
                                        sx={{
                                            my: 2,
                                            opacity: 0.2,
                                            borderColor: 'grey.500',
                                        }}
                                    />
                                    {tier.description.map((line) => (
                                        <Box
                                            key={line}
                                            sx={{
                                                py: 1,
                                                display: 'flex',
                                                gap: 1.5,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CheckCircleRoundedIcon
                                                sx={{
                                                    width: 20,
                                                    color:
                                                        tier.title === 'Infinite'
                                                            ? 'primary.light'
                                                            : 'primary.main',
                                                }}
                                            />
                                            <Typography
                                                component="div"
                                                variant="subtitle2"
                                                sx={{
                                                    color:
                                                        tier.title === 'Infinite' ? 'grey.200' : undefined,
                                                }}
                                            >
                                                {line}
                                            </Typography>
                                        </Box>
                                    ))}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        fullWidth
                                        variant={tier.buttonVariant as 'outlined' | 'contained'}
                                        onClick={() => {
                                            setDialoge(true)
                                            setSelectedPlan(index)
                                        }}
                                    // component="a"
                                    // href="/material-ui/getting-started/templates/checkout/"
                                    // target="_blank"
                                    >
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Dialog
                open={dialoge}
                onClose={() => {
                    setDialoge(false)
                    setSelectedPlan(1)
                }}>
                {
                    tiers[selectedPlan].price ?
                        <PaymentDialog am={Number(tiers[selectedPlan].withgst)} onClose={() => {
                            setDialoge(false)
                            setSelectedPlan(1)
                        }}
                        />
                        :
                        <>
                            <DialogTitle>
                                Digital Marketing - Contact Us
                            </DialogTitle>
                            <DialogContent>
                                <Typography variant="h4" >Contact us</Typography>
                                <Typography variant="body1">To do digital marketing, please contact us using any of the options below. We have various plans tailored to your business needs. Whether you're a small startup or a large enterprise, we're here to help you succeed. Get in touch with our team today to discuss your requirements and find the perfect solution for your business.</Typography>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'center' }}>
                                <Tooltip title="Call" arrow placement="top">
                                    <IconButton onClick={() => window.location.href = (`tel:${9994149559}`)} size="small"><CallIcon fontSize='medium' color='primary' /></IconButton>
                                </Tooltip>
                                <Tooltip title="Call" arrow placement="top">
                                    <IconButton size="small" onClick={() => window.open(`https://wa.me/91${9994149559}?text=${encodeURIComponent("Hi there! 👋 \nI'm interested in growing my business digitally.Could you please provide me with information about your digital marketing plans and prices? I'm eager to explore how your services can help my business thrive online. Thanks!")}`)}><WhatsAppIcon fontSize='medium' color='primary' /></IconButton>
                                </Tooltip>
                            </DialogActions>
                            {/* <DialogContent>
                                <Typography variant="caption">View Our Profile at <Link href="https://www.vybs.in/bizcard/gk" target="_blank">vibs.in</Link></Typography>
                            </DialogContent> */}
                            <DialogActions>
                                <Button onClick={() => setDialoge(false)} variant='outlined'>Close</Button>
                            </DialogActions>
                        </>
                }
            </Dialog>
        </>
    );
}


export default function Subscription() {
    return (
        <Pricing />
    )

}

export function SubscriptionModal() {
    const [isOpen, setIsOpen] = useRecoilState(subscriptionModalState)

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth='lg'>
            <Subscription />
        </Dialog>
    )
}
