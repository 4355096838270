import { BizTemplatePosterType, GreetPosterType, PosterImagesType, templateDataType } from "./boostify.types";
import bizlogo from '../../Biz/images/boostify-logo.png'

export const boostifyDefaultData: templateDataType = {
    logo: '',
    profile: '',
    pname: '',
    bnmae: '',
    address: '',
    mobile: '',
    mobile2: '',
    mail: '',
    mail2: '',
    web: '',
    sm: { insta: '', fb: '', tw: '', yt: '' },
    watermark: '',
    tagline: '',
}


export const boostifyFreeDefaultData: templateDataType = {
    logo: bizlogo,
    profile: '',
    pname: 'BOOSTIFY.BIZ',
    bnmae: 'BOOSTIFY.BIZ',
    address: 'Boostify, 123, XYZ, ABC, 123456',
    mobile: '999XXXXXXX',
    mobile2: '987XXXXXXX',
    mail: 'info@boostify.biz',
    mail2: 'care@boostify.biz',
    web: 'https://boostify.biz/',
    sm: { insta: 'https://www.instagram.com/', fb: 'https://www.facebook.com/', tw: 'https://twitter.com/', yt: 'https://www.youtube.com/' },
    watermark: 'BOOSTIFY.BIZ',
}

export const posterImageDefaultData: PosterImagesType = {
    id: '',
    title: '',
    description: '',
    imgurl: '',
    priority: 0
}

export const greetPosterDefaultData: GreetPosterType = {
    title: '',
    description: '',
    images: {},
    // link: '',
    date: ''
}

export const bizPosterDefaultData: BizTemplatePosterType = {
    title: '',
    description: '',
    imgurl: '',
    priority: 0,
    images: {},
    vertical: ''
}