import { LegacyRef } from 'react'
import { templateDataType } from '../../state/boostify/boostify.types'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import fbIcon from '../assets/fb.png'
import instaIcon from '../assets/insta.png'
import xIcon from '../assets/x.png'
import ytIcon from '../assets/yt.png'
import BizWarermark from '../BizWarermark';
import './BizImageTemplate7.css'

export default function BizImageTemplate7({
    templateData, bizRef, rawImg, onLoadRawImg
}: {
    templateData: templateDataType,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void
}) {

    return (
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    // overflow: 'scroll'
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{
                            position: 'absolute', top: '0', left: '0',
                            // transform: 'translate(-50%, -50%)'
                        }}
                    >
                        <img
                            src={rawImg}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    {
                        templateData.logo ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '8%', right: '8%', transform: 'translate(-10%, -50%)' }}
                            >
                                <img
                                    src={templateData.logo}
                                    alt={templateData.bnmae ?? 'Boostify-Biz'}
                                    // onLoad={onLoadRawImg}
                                    style={{
                                        width: '120px',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                            : ''
                    }

                    <div
                        className="biz-contact-info"
                        style={{ position: 'absolute', top: '85%', width: '100%', left: '5%', }}
                    >
                        <div
                            className="biz-footer-all-content"
                            style={{ display: 'flex' }}
                        >
                            {
                                templateData.profile ?
                                    <div
                                        className="biz-img-body"
                                        style={{ height: '120px', width: '120px' }}
                                    >
                                        <img
                                            src={templateData.profile}
                                            alt={templateData.pname ?? 'Boostify-Biz'}
                                            // onLoad={onLoadRawImg}
                                            style={{
                                                width: '120px',
                                                objectFit: 'cover',
                                                border: '2px solid black', borderRadius: '20px',
                                            }}
                                        />
                                    </div>
                                    : ''
                            }

                            <div className="biz-remaining-content" style={{ width: '80%' }}>
                                <div
                                    className="biz-bname-temp7"
                                    style={{
                                        textAlign: 'right', fontSize: '35px', fontWeight: 'bolder',
                                        background: '#3e3e3e', color: 'white',
                                        // marginTop: '16px', 
                                        padding: '5px 20px',
                                        display: 'inline-block',
                                        borderRadius: '20px 10px 0 0',
                                        position: 'relative',
                                    }}
                                >
                                    {templateData.bnmae ?? 'BOOSTIFY.BIZ'}
                                </div>

                                <div
                                    className="biz-mail-web-mobile-pname"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        // gap: '50px',
                                        color: 'black',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        className="biz-pname-mobile"
                                        style={{
                                            background: '#f0f0f0',
                                            borderTop: '2px solid black',
                                            borderRadius: '0 0 0 20px',
                                            paddingLeft: '10px'
                                        }}
                                    >
                                        <div style={{ fontSize: '20px' }}>{templateData.pname}</div>
                                        <div
                                            className='biz-mobile'
                                            style={{
                                                display: 'flex', gap: 5, fontSize: '20px', justifyContent: 'center',
                                                // background: '#ec1d8b',
                                                alignItems: 'center',
                                                position: 'relative',
                                            }}
                                        >
                                            <div style={{ display: 'flex' }}><CallIcon /></div>
                                            <div>{templateData.mobile}</div>
                                        </div>
                                    </div>
                                    <div
                                        className="biz-mail-and-web"
                                        style={{
                                            background: '#f0f0f0',
                                            borderTop: '2px solid black',
                                            paddingLeft: '50px',
                                            paddingRight: '50px',
                                            borderRadius: '0 20px 0 0',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex', gap: 5, fontSize: '20px', justifyContent: 'center',
                                                // background: '#0056a2',
                                                alignItems: 'center',
                                                // marginRight: '-60px',
                                                position: 'relative',
                                            }}
                                            className='biz-mail'
                                        >
                                            <div style={{ display: 'flex' }}><EmailIcon /></div>
                                            <div>{templateData.mail}</div>
                                        </div>
                                        <div
                                            className='biz-web'
                                            style={{
                                                display: 'flex', gap: 5, fontSize: '20px', justifyContent: 'center',
                                                // background: '#ec1d8b',
                                                alignItems: 'center',
                                                position: 'relative',
                                            }}
                                        >
                                            <div style={{ display: 'flex' }}><LanguageIcon /></div>
                                            <div>{templateData.web}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {
                        templateData?.watermark && <BizWarermark watermark={templateData.watermark} />
                    }
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
                    >
                        <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
