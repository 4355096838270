import { useEffect, useState } from 'react'
import { authState, empRoleState, masterRoleState, profileState, userState } from '../../state/user/user.atom'
import { dmLicenseState, todayDownloadIDState } from '../../state/license/license.atom'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { collection, doc, getDoc, limit, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../utils/firebase/firebase'
import { dmLicenseType } from '../../state/license/license.types'
import { EmpRoleType, ProfileDataType, UserDataType } from '../../state/user/user.types'
import { boostifyTemplateDataState } from '../../state/boostify/boostify.atom'
import { bizSubVerticalDBState, bizVerticalDBState } from '../../state/bizVertical/bizVertical.atom'

function BizTempageDataLoader() {
    const profileData = useRecoilValue(profileState)
    const setBoostifyTemplateData = useSetRecoilState(boostifyTemplateDataState)

    useEffect(() => {
        const getLogo = async () => {

            const res = await fetch(profileData.profileImg.logourl)
            const reader = new FileReader()
            reader.onloadend = () => {
                setBoostifyTemplateData(prevState => {
                    return {
                        ...prevState,
                        logo: reader.result as string
                    }
                })
            }
            reader.readAsDataURL(await res.blob())
        }
        if (profileData?.profileImg?.logourl)
            getLogo()

    }, [profileData?.profileImg?.logourl])

    useEffect(() => {
        const getLogo = async () => {
            const res = await fetch(profileData.profileImg.photourl)
            const reader = new FileReader()
            reader.onloadend = () => {
                setBoostifyTemplateData(prevState => {
                    return {
                        ...prevState,
                        profile: reader.result as string
                    }
                })
            }
            reader.readAsDataURL(await res.blob())
        }
        if (profileData?.profileImg?.photourl)
            getLogo()

    }, [profileData?.profileImg?.photourl])

    useEffect(() => {
        const setProfileData = async () => {
            const { contact, profile, profileImg, sm } = profileData

            if (!profile) return;
            setBoostifyTemplateData(prevState => ({
                ...prevState,
                bnmae: profile?.bname ?? '',
                pname: profile?.name ?? '',
                mail: contact?.mail?.[0] ?? '',
                mail2: contact?.mail?.[1] ?? '',
                mobile: (contact?.mobile?.[0] ?? '') + '',
                mobile2: (contact?.mobile?.[1] ?? '') + '',
                address: (contact?.address ?? '').replaceAll('\n', ' '), // profile.address,
                sm: {
                    fb: sm?.fb?.[0] ?? '',
                    insta: sm?.insta?.[0] ?? '',
                    // li: sm.li[0],
                    // pin: sm.pin[0],
                    tw: sm?.x?.[0] ?? '',
                    // wa: sm.wa[0],
                    yt: sm?.yt?.[0] ?? '',
                },
                web: (contact?.web?.[0] ?? '').split('https://')[1],
                watermark: profile?.watermark ?? '',
                tagline: profile?.tagline ?? '',
            }))
        }
        setProfileData()

    }, [profileData])

    return <></>
}

export default function GetData() {
    const { uid } = useRecoilValue(authState)
    const [userData, setUserData] = useRecoilState(userState)
    const resetUserData = useResetRecoilState(userState)
    const setProfileData = useSetRecoilState(profileState)
    const resetProfileData = useResetRecoilState(profileState)
    const setEmpRole = useSetRecoilState(empRoleState)
    const resetEmpRole = useResetRecoilState(empRoleState)
    const setMasterRole = useSetRecoilState(masterRoleState)
    const resetMasterRole = useResetRecoilState(masterRoleState)
    const setBizVertical = useSetRecoilState(bizVerticalDBState)
    const setBizSubVertical = useSetRecoilState(bizSubVerticalDBState)
    const [profileLoading, setProfileLoading] = useState(true)

    const dmLicense = useRecoilValue(dmLicenseState)
    const setDMLicense = useSetRecoilState(dmLicenseState)

    const setTodayDownloadID = useSetRecoilState(todayDownloadIDState)

    useEffect(() => {
        const getEmpRole = async () => {
            try {
                const docRef = await getDoc(doc(db, 'emproles', uid))
                if (!docRef.exists()) {
                    return;
                }
                const data = docRef.data()
                setEmpRole(data as EmpRoleType)
            } catch (error) {
                console.error(error);
            }
        }

        if (uid)
            getEmpRole()

        return (() => {
            resetEmpRole()
        })
    }, [uid])

    useEffect(() => {
        const getMasterRole = async () => {
            try {
                const docRef = await getDoc(doc(db, 'mroles', uid))
                if (!docRef.exists()) {
                    return;
                }
                const data = docRef.data()
                setMasterRole(data as EmpRoleType)
            } catch (error) {
                console.error(error);
            }
        }

        if (uid)
            getMasterRole()

        return (() => {
            resetMasterRole()
        })
    }, [uid])

    useEffect(() => {
        let unSubscribe: () => void
        const getLicenseData = async () => {
            try {
                const docRef = doc(db, 'dmlic', userData.defaultpid)
                unSubscribe = onSnapshot(docRef, async (qSnapshot) => {

                    if (!qSnapshot.exists()) {
                        setDMLicense({} as dmLicenseType)
                        return;
                    }
                    const data = qSnapshot.data()
                    setDMLicense(data as dmLicenseType)
                    return;
                })
            } catch (error) {
                console.error(error);
            }
        }

        if (userData.defaultpid)
            getLicenseData()

        return (() => {
            if (unSubscribe)
                unSubscribe()
            setDMLicense({} as dmLicenseType)
        })

    }, [userData.defaultpid])

    useEffect(() => {
        let unSubscribe: () => void
        const getUserdData = () => {
            try {
                const docRef = doc(db, 'users', uid)
                unSubscribe = onSnapshot(docRef, async (qSnapshot) => {
                    if (!qSnapshot.exists()) {
                        return;
                    }
                    const data = qSnapshot.data()
                    const docid = qSnapshot.id
                    const temppid = data.temppid
                    const temptime = data.temptime
                    if (temppid && temptime) {
                        const tt = new Date(parseInt(temptime, 36))
                        const now = new Date()
                        if (now < tt) {
                            return setUserData({ ...data, defaultpid: temppid } as UserDataType)
                        }
                    }
                    setUserData({ ...data, docid } as UserDataType)
                    return;
                })

            } catch (error) {
                console.error(error);
            }
        }

        getUserdData()

        return (() => {
            if (unSubscribe)
                unSubscribe()
            resetUserData()
        })

    }, [])

    useEffect(() => {
        let unSubscribe: () => void
        const getProfileData = async () => {
            try {
                const collRef = collection(db, 'profiles')
                const q = query(collRef, where('pid', '==', userData.defaultpid), limit(1))
                unSubscribe = onSnapshot(q, (qSnapshot) => {
                    if (qSnapshot.empty) return;
                    const data = qSnapshot.docs[0].data()
                    const docid = qSnapshot.docs[0].id
                    setProfileData({ ...data, docid } as ProfileDataType)
                    setProfileLoading(false)
                })

            } catch (error) {
                console.error(error);
            }
        }

        if (!!userData?.defaultpid)
            getProfileData()

        return (() => {
            if (unSubscribe)
                unSubscribe()
            resetProfileData()
        })

    }, [userData.defaultpid])

    useEffect(() => {
        let unSubscribe: () => void
        const getTodayDownloadID = async () => {
            const today = Number(new Date(new Date().toDateString())).toString(36)
            const docRef = doc(db, 'dm', 'posters', 'starterdownload', userData.defaultpid)
            unSubscribe = onSnapshot(docRef, async (qSnapshot) => {
                if (!qSnapshot.exists()) return setTodayDownloadID('');
                const data = qSnapshot.data() as { today: string, id: string }
                if (data.today === today)
                    setTodayDownloadID(data.id)
                else setTodayDownloadID('')
            })
        }

        if (userData.defaultpid && (dmLicense.name === 'Starter'))
            getTodayDownloadID()
        else setTodayDownloadID('')

        return () => {
            if (unSubscribe)
                unSubscribe()
            setTodayDownloadID('')
        }

    }, [userData.defaultpid, dmLicense.name])

    useEffect(() => {
        const getBizVertical = async () => {
            try {
                const docRef = doc(db, 'defaults', 'bizvertical')
                const docSnap = await getDoc(docRef)
                if (!docSnap.exists()) {
                    return;
                }
                const data = docSnap.data()
                setBizVertical(data)
            } catch (error) {
                console.error(error);
            }
        }

        const getBizSubVertical = async () => {
            try {
                const docRef = doc(db, 'defaults', 'bizsubvertical')
                const docSnap = await getDoc(docRef)
                if (!docSnap.exists()) {
                    return;
                }
                const data = docSnap.data()
                setBizSubVertical(data)
            } catch (error) {
                console.error(error);
            }
        }

        getBizVertical()
        getBizSubVertical()

        return () => {
            setBizVertical({})
            setBizSubVertical({})
        }

    }, [])


    if (!profileLoading) {
        return <BizTempageDataLoader />
    }

    return (
        <></>
    )
}
