
export default function BizWarermark({ watermark = 'BOOSTIFY.BIZ' }: { watermark?: string }) {

    return (
        <>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '20%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '40%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '60%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '80%' }}
            >
                {watermark}
            </div>


            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '20%', left: '25%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '40%', left: '25%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '60%', left: '25%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '80%', left: '25%' }}
            >
                {watermark}
            </div>


            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '20%', left: '50%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '40%', left: '50%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '60%', left: '50%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '80%', left: '50%' }}
            >
                {watermark}
            </div>


            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '20%', left: '75%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '40%', left: '75%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '60%', left: '75%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px', color: '#00000040', textAlign: 'center', top: '80%', left: '75%' }}
            >
                {watermark}
            </div>


            {/* <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px',  color: '#00000040', textAlign: 'center', top: '20%', left: '95%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px',  color: '#00000040', textAlign: 'center', top: '40%', left: '95%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px',  color: '#00000040', textAlign: 'center', top: '60%', left: '95%' }}
            >
                {watermark}
            </div>
            <div
                className="boostify-watermark"
                style={{ transform: 'rotate(315deg)', position: 'absolute', fontSize: '30px',  color: '#00000040', textAlign: 'center', top: '80%', left: '95%' }}
            >
                {watermark}
            </div> */}
        </>
    )
}
