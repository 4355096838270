import { selector, selectorFamily } from "recoil";
import { dmLicenseState } from "./license.atom";
import { dmLicenseFeatureType } from "./license.types";

export const dmLicenseValidState = selector({
    key: 'dmLicenseValidState',
    get: ({ get }) => {
        const license = get(dmLicenseState)
        if (!license?.validTill) return false
        const today = Number(new Date()).toString(36)

        return license.validTill > today
    }
})

export const dmLicenseFeatureState = selectorFamily({
    key: 'dmLicenseFeatureState',
    get: (feature: keyof dmLicenseFeatureType) => ({ get }) => {
        const license = get(dmLicenseState)
        return license?.features?.[feature] || false
    }
})
