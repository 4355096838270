import { useRecoilValue, useSetRecoilState } from 'recoil'
import { authState, loginModalState } from '../../state/user/user.atom'
import { auth } from '../../utils/firebase/firebase'

export default function useAuth() {
    // const isLoggedIn = useRecoilValue(logged)
    const { uid } = useRecoilValue(authState)
    const setLoginModal = useSetRecoilState(loginModalState)

    const login = () => {
        return setLoginModal(true)
    }

    const withAuth = (fn: (...args: any[]) => void) => {

        return async (...args: any[]) => {
            const isLoggedIn = !!uid
            if (isLoggedIn) {
                return await fn(...args);
            } else {
                login();
            }
        };
    }

    const forceLogout = () => {
        return auth.signOut()
    }

    const logout = async () => {
        try {
            return await auth.signOut()
        } catch (err) {
            console.error({ err });
        }
    }

    return { login, logout, forceLogout, withAuth }
}
