export const registerSW = () => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/sw.js`)
    }
}

export const unRegisterSW = () => {
    if ('serviceWorker' in navigator) {
        caches.keys().then(keys => {
            return Promise.all(keys.map(key => caches.delete(key)))
        })
        navigator.serviceWorker.getRegistrations().then(registerations => {
            for (let registeration of registerations)
                registeration.unregister()
        })
    }
}
