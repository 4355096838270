import { atom } from "recoil";
import { BizSubVerticalType, BizVerticalType } from "./bizVertical.types";

export const bizVerticalDBState = atom({
    key: 'bizVerticalDBState',
    default: {} as { [key: string]: BizVerticalType }
})

export const bizSubVerticalDBState = atom({
    key: 'bizSubVerticalDBState',
    default: {} as { [key: string]: BizSubVerticalType }
})