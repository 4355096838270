import componentLoader from "./utils/CustomComponent/ComponentLoader";
import { Backdrop, CircularProgress, Container, ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState, lazy, Suspense } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { empRoleState, masterRoleState, themeState } from "./state/user/user.atom";
import AuthComp from "./components/auth/Auth";
import Nav from "./components/nav/Nav";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./components/Home/Home";
import 'firebaseui/dist/firebaseui.css'
import Subscription, { SubscriptionModal } from "./components/subscription/SubscriptionPage";
import { isLoggedInState } from "./state/user/user.selector";
import "react-multi-carousel/lib/styles.css";

import { dmLicenseValidState } from "./state/license/license.selector";
// import CertDownloadPage from "./components/cert/Cert";
// import CertDirectDownloadPage from "./components/cert/DirectCert";

const BizFolder = lazy(() => componentLoader(() => import("./components/MyBiz/BizFolders/BizFolder")));
const BizDefaults = lazy(() => componentLoader(() => import("./components/Master/Biz/BizDefaults")));
const BizVertical = lazy(() => componentLoader(() => import("./components/Master/Biz/BizVertical")));
const BizSubVertical = lazy(() => componentLoader(() => import("./components/Master/Biz/BizSubVertical")));
const BizPosters2 = lazy(() => componentLoader(() => import("./components/Emp/Biz/BizPosters2")));
const Account = lazy(() => componentLoader(() => import("./components/Account/Account")));
const Emp = lazy(() => componentLoader(() => import("./components/Emp/Emp")));
const GreetPosters = lazy(() => componentLoader(() => import("./components/Emp/Greet/GreetPosters")));
const Master = lazy(() => componentLoader(() => import("./components/Master/Master")));
const DMLicensePage = lazy(() => componentLoader(() => import("./components/Master/DMLicense")));
const Profile = lazy(() => componentLoader(() => import("./Profile/Profile")));
const LuckyDraw = lazy(() => componentLoader(() => import("./components/LuckyDraw/LuckyDraw")));
const TempPidUpdate = lazy(() => componentLoader(() => import("./components/Master/TempPidUpdate")));

const Greet = lazy(() => componentLoader(() => import("./components/Greet/Greet")));
const MyBiz = lazy(() => componentLoader(() => import("./components/MyBiz/MyBiz")));
const PrivacyPolicy = lazy(() => componentLoader(() => import("./Policies/PrivacyPolicy")));
const TermsOfService = lazy(() => componentLoader(() => import("./Policies/TermsOfService")));
const AcceptableUsePolicy = lazy(() => componentLoader(() => import("./Policies/AccaptableUsePolicy")));
const CookiePolicy = lazy(() => componentLoader(() => import("./Policies/CookiePolicy")));
const CopyrightPolicy = lazy(() => componentLoader(() => import("./Policies/CopyrightPolicy")));
const RefundPolicy = lazy(() => componentLoader(() => import("./Policies/RefundPolicy")));
const Policies = lazy(() => componentLoader(() => import("./Policies/Policies")));


const Loading = () => {
  return <Backdrop open={true}>
    <CircularProgress />
  </Backdrop>
}

function MainPage() {
  const isLoggedIn = useRecoilValue(isLoggedInState)
  const isDMLicValid = useRecoilValue(dmLicenseValidState)
  const empRole = useRecoilValue(empRoleState)
  const masterRole = useRecoilValue(masterRoleState)

  return (
    <div className="content-page">
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/cert' element={<CertDownloadPage />} />
        <Route path='/dircert' element={<CertDirectDownloadPage />} /> */}
        <Route path='/luckydraw' element={<LuckyDraw />} />
        <Route path='/home' element={<Home />} />
        <Route path="/greet/:greetid" element={<Suspense fallback={<Loading />}><Greet /></Suspense>} />
        <Route path='/mybiz' element={<Suspense fallback={<Loading />}>
          {
            (isLoggedIn && isDMLicValid) ?
              <MyBiz />
              : <Subscription />
          }
        </Suspense>
        } />
        <Route path='/mybiz/biz'
          element={
            <Suspense fallback={<Loading />}>
              {

                (isLoggedIn && isDMLicValid) ? <BizFolder /> : <Subscription />
              }
            </Suspense>
          }
        />

        <Route path='/profile' element={<Suspense fallback={<Loading />}>{(isLoggedIn && isDMLicValid) ? <Profile /> : <Subscription />}</Suspense>} />
        <Route path='/account' element={<Suspense fallback={<Loading />}>{(isLoggedIn && isDMLicValid) ? <Account /> : <Subscription />}</Suspense>} />

        <Route path='/emp' element={<Suspense fallback={<Loading />}>{(empRole?.isenabled) ? <Emp /> : <Home />}</Suspense>} >
          <Route path='biz' element={<BizPosters2 />} />
          <Route path='greet' element={<GreetPosters />} />
          <Route path='tiru' element={<GreetPosters />} />
          <Route path='moti' element={<GreetPosters />} />
        </Route>
        <Route path='/master' element={<Suspense fallback={<Loading />}>{(masterRole?.isenabled) ? <Master /> : <Home />}</Suspense>} >
          <Route path='subs' element={<DMLicensePage />} />
          <Route path='tempprofile' element={<TempPidUpdate />} />
          <Route path='gift' element={<Subscription />} />
          <Route path='defaults' element={<BizDefaults />}>
            <Route path='vertical' element={<BizVertical />} />
            <Route path='subvertical' element={<BizSubVertical />} />
          </Route>
        </Route>

        <Route path='/pricing' element={<Subscription />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsOfService />} />
        <Route path='/acceptable-use-policy' element={<AcceptableUsePolicy />} />
        <Route path='/cookie-policy' element={<CookiePolicy />} />
        <Route path='/copyright-policy' element={<CopyrightPolicy />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/policies' element={<Policies />} />
        <Route path='*' element={<Home />} />
      </Routes>
    </div>
  )
}

function ThemeFunction({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useRecoilState(themeState)
  const [themeToProvider, setThemeToProvider] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    const getBrowserTheme = () => {
      const isDarkModeEnabled = window.matchMedia('(prefers-color-scheme: dark)').matches;
      return isDarkModeEnabled ? 'dark' : 'light'
    }

    const root = document.documentElement;
    const currentTheme = root.getAttribute("data-theme")

    const newTheme = (theme === 'browser') ? getBrowserTheme() : 'light'

    if (currentTheme !== newTheme)
      root.setAttribute("data-theme", theme)
    if (currentTheme !== themeToProvider)
      setThemeToProvider(newTheme)

  }, [theme])

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const allThemes = {
    light: lightTheme,
    dark: darkTheme
  }


  return (
    <ThemeProvider theme={allThemes[themeToProvider]}>
      {children}
    </ThemeProvider>
  );
}

function App() {


  return (
    <ThemeFunction>
      <AuthComp />
      <SubscriptionModal />
      <BrowserRouter>
        <Nav />
        <Container component={'main'}>
          <MainPage />
        </Container>
      </BrowserRouter>


    </ThemeFunction>
  );
}

export default App;
