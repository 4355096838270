import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import * as firebaseui from 'firebaseui'
import { EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'
import { loginModalState } from '../../state/user/user.atom'
import { auth } from '../../utils/firebase/firebase'
import ReactModal from '../../utils/CustomComponent/ReactModal'
import { Typography, Link } from '@mui/material'

const Login = () => {
    const [isLogin, setIsLogin] = useState(false)

    useEffect(() => {
        let fui: firebaseui.auth.AuthUI
        // if (isLogin) {
        fui = new firebaseui.auth.AuthUI(auth)

        const uiConfig: firebaseui.auth.Config = {
            signInOptions: [
                { provider: GoogleAuthProvider.PROVIDER_ID },
                // { provider: EmailAuthProvider.PROVIDER_ID, },
                // { provider: FacebookAuthProvider.PROVIDER_ID, },
                // { provider: PhoneAuthProvider.PROVIDER_ID, }
            ],
            // signInSuccessUrl: window.location.href.replace('authenticate=true', ''),
            signInFlow: 'popup',
        }

        fui.start('#firebaseui-auth-container', uiConfig)
        window.localStorage.setItem('islogin', 'YES')
        // }

        return () => {
            // fui.reset()
            if (fui)
                fui.delete()
        }
    }, [isLogin])

    return (
        <div className="login-comp-wrapper" >
            <div className="login-comp">
                <div className="login-header">
                    Login / Signup
                </div>
                <div className="login-body">
                    <div id='firebaseui-auth-container' />
                </div>
                <div className="login-footer">
                    <Typography variant='caption' color='textSecondary'>
                        By clicking on the Sign In above, you agree to our
                        <Link href="/policies" color="primary" component={'a'} target='_blank'>
                            {" "}policies{" "}
                        </Link>
                        .
                    </Typography>
                </div>
            </div>
        </div>
    )

}

export default function LoginModal() {
    // const islogin = useQuery('authenticate')
    const [isOpen, setIsOpen] = useRecoilState(loginModalState)

    const onClose = () => {
        setIsOpen(false)
        window.localStorage.removeItem('islogin')
    }

    return (
        <ReactModal
            isopen={isOpen}
            isbutton={false}
            Component={Login}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            onClose={onClose}
        />
    )
}
