import { LegacyRef } from 'react'
import { templateDataType } from '../state/boostify/boostify.types'
import BizImageTemplate1 from './BizImageTemplates/BizImageTemplate1'
import BizImageTemplate2 from './BizImageTemplates/BizImageTemplate2'
import BizImageTemplate3 from './BizImageTemplates/BizImageTemplate3'
import BizImageTemplate4 from './BizImageTemplates/BizImageTemplate4'
import BizImageTemplate5 from './BizImageTemplates/BizImageTemplate5'
import BizImageTemplate6 from './BizImageTemplates/BizImageTemplate6'
import BizImageTemplate7 from './BizImageTemplates/BizImageTemplate7'
import BizImageTemplate8 from './BizImageTemplates/BizImageTemplate8'
import BizImageTemplate9 from './BizImageTemplates/BizImageTemplate9'

export default function BizImageTemplates({
    tname, templateData, bizRef, rawImg, onLoadRawImg
}: {
    tname: string,
    templateData: templateDataType,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void
}) {

    switch (tname) {
        case 'template2': return <BizImageTemplate2 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template3': return <BizImageTemplate3 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template4': return <BizImageTemplate4 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template5': return <BizImageTemplate5 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template6': return <BizImageTemplate6 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template7': return <BizImageTemplate7 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template8': return <BizImageTemplate8 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template9': return <BizImageTemplate9 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
        case 'template1':
        default: return <BizImageTemplate1 templateData={templateData} bizRef={bizRef} rawImg={rawImg} onLoadRawImg={onLoadRawImg} />
    }
}
