import { LegacyRef } from 'react'
import { templateDataType } from '../../state/boostify/boostify.types'
import CallIcon from '@mui/icons-material/Call';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import fbIcon from '../assets/fb.png'
import instaIcon from '../assets/insta.png'
import xIcon from '../assets/x.png'
import ytIcon from '../assets/yt.png'
import BizWarermark from '../BizWarermark';

export default function BizImageTemplate2({
    templateData, bizRef, rawImg, onLoadRawImg
}: {
    templateData: templateDataType,
    bizRef: LegacyRef<HTMLDivElement>,
    rawImg: string,
    onLoadRawImg: () => void
}) {

    return (
        <div
            style={{ position: 'relative' }}
        >
            <div
                className="biz-img-container-to-hide"
                style={{
                    position: 'absolute',
                    top: -99999999
                }}
            >
                <div
                    className="biz-img-container"
                    style={{ position: 'relative', height: 1080 + 'px', width: 1080 + 'px' }}
                    ref={bizRef}
                >
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                    >
                        <img
                            src={rawImg}
                            alt={'Boostify-Biz'}
                            onLoad={onLoadRawImg}
                            style={{ width: 1080 + 'px', height: 1080 + 'px', objectFit: 'cover' }}
                        />
                    </div>
                    {
                        templateData.logo ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '10%', left: '10%', transform: 'translate(-10%, -50%)' }}
                            >
                                <img
                                    src={templateData.logo}
                                    alt={templateData.bnmae ?? 'Boostify-Biz'}
                                    // onLoad={onLoadRawImg}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                        borderRadius: '50%'
                                    }}
                                />
                            </div>
                            : ''
                    }
                    {
                        templateData.profile ?
                            <div
                                className="biz-img-body"
                                style={{ position: 'absolute', top: '10%', right: '10%', transform: 'translate(-10%, -50%)' }}
                            >
                                <img
                                    src={templateData.profile}
                                    alt={templateData.bnmae ?? 'Boostify-Biz'}
                                    style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                        borderRadius: '50%'
                                    }}
                                />
                            </div>
                            : ''
                    }
                    <div
                        className="biz-img-body"
                        style={{ position: 'absolute', top: 86 + '%', width: '100%' }}
                    >
                        <div className="business-name" style={{ textAlign: 'center', margin: '0 auto', fontSize: '40px', color: 'black', fontWeight: 'bolder', }}>
                            {templateData.bnmae ?? 'BOOSTIFY.BIZ'}
                        </div>
                    </div>
                    <div
                        className="biz-contact-info"
                        style={{ position: 'absolute', top: '92%', width: '100%', }}
                    >
                        <div className="biz-primary-contact" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', gap: 5 }}>
                            <span style={{ fontSize: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><CallIcon />{templateData?.mobile}{templateData.mobile2 ? ',' + templateData.mobile2 : ''}</span>
                            <span>{" "}</span>
                            <span style={{ fontSize: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><EmailIcon />{templateData?.mail}{templateData.mail2 ? ',' + templateData.mail2 : ''}</span>
                            <span>{" "}</span>
                            <span style={{ fontSize: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><LanguageIcon />{templateData?.web}</span>
                        </div>

                        <div
                            className="biz-address"
                            style={{ textAlign: 'center', fontSize: '20px' }}
                        >
                            {templateData?.address}
                        </div>
                        {/* <div className="biz-sm-data" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: '5px', gap: 5 }}>
                            {
                                templateData?.sm?.fb ?
                                    <span style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={fbIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                            {
                                templateData?.sm?.insta ?
                                    <span style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={instaIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                            {
                                templateData?.sm?.tw ?
                                    <span style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={xIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                            {
                                templateData?.sm?.yt ?
                                    <span style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={ytIcon} width={'20px'} height={"20px"} />{''}</span>
                                    : ''
                            }
                        </div> */}
                    </div>
                    {
                        templateData?.watermark && <BizWarermark watermark={templateData.watermark} />
                    }
                    <div
                        className="biz-img-footer"
                        style={{ textAlign: 'center', fontSize: 'smaller', width: '100%', position: 'absolute', bottom: '10px' }}
                    >
                        <div className="copyright" style={{ fontSize: '10px', color: 'black' }}>
                            All Rights Reserved. © 2024 to www.Boostify.Biz
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
